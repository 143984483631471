// For more Icons take a lokk at https://fontawesome.com/

const WLCONFG= {
    SHOW_BLOG:                      false,
    USE_CONTENT_IMAGE_ERROR_PAGE:   true,
    SHOW_DASHBOARD_SIDEBAR:         false,
    SHOW_BLOG_SIDEBAR:              true,
    SHOW_BLOG_ARTICLE_SIDEBAR:      true,
    SHOW_ACADEMY_SIDEBAR:           false,
    SHOW_ACADEMY_LESSON_SIDEBAR:    true,
    SHOW_PRODUCT_SIDEBAR:           false,
    SHOW_CHECKLIST_SIDEBAR:         false,
    SHOW_STATISTIC_SIDEBAR:         false,
    ACCESSLEVEL:                    '-1',
    ICON_NAVIGATION_DASHBOARD:      'gauge-high',
    ICON_NAVIGATION_MY_PRODUCTS:    'cart-arrow-down',
    ICON_NAVIGATION_STATISTICS:     'chart-pie',
    ICON_NAVIGATION_ACADEMY:        'graduation-cap',
    ICON_NAVIGATION_BLOG:           'book',
    ICON_NEWS:                      'bullhorn',
    ICON_CHARTS:                    'chart-area',
    ICON_OFFER:                     'basket-shopping',
    LOGO_SIGNET:                    'logo_abrix_white_signet.png',
    LOGO:                           'logo_abrix.png',
    ERROR_LOGO:                     'abrix_error_logo.png',
    APP_META_TITLE:                 'Abrix',
    FAVICON_FOLDER:                 'abrix',
    META_SAFE_EQUIV:                'member.abrix.de',
    COMPANY_NAME:                   'ABRIX GmbH',
    COMPANY_SHORT_NAME:             'Abrix',
    COMPANY_ADDRESS:                'Wannerstr. 30, 45661 Recklinghausen, Deutschland',
    COMPANY_EMAIL:                  'info[@]abrix.eu',
    COMPANY_PHONE:                  '+4923618903327'
}

export  {
    WLCONFG
}
